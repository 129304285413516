<template>
    <div id="supervisor-dashboard">
        <b-overlay :show="showLoading" spinner-variant="primary">
            <b-card no-body class="card-statistics" v-for="(item, index) in companies" :key="index">
                <b-card-header>
                    <b-card-title>{{ item.company }}</b-card-title>
                    <b-card-text class="mr-25 mb-0">
                        Real Time Data <feather-icon class="ml-5 align-middle" :class="showLoading ? 'spinner' : ''"
                            style="cursor: pointer;" size="24" icon="RefreshCwIcon" @click="loadData" />
                    </b-card-text>

                </b-card-header>
                <b-card-body class="statistics-body">
                    <b-row>
                        <b-col md="3" sm="6" class="mb-2 mb-md-0 mb-xl-0">
                            <b-media no-body>
                                <b-media-aside class="mr-2">
                                    <b-avatar size="48" variant="light-warning">
                                        <feather-icon size="24" icon="UserUpIcon" />
                                    </b-avatar>
                                </b-media-aside>
                                <b-media-body class="my-auto">
                                    <h4 class="font-weight-bolder mb-0">
                                        Customers
                                    </h4>
                                    <b-card-text class="font-small-3 mb-0">
                                        {{ item.customersNumber }}
                                    </b-card-text>
                                </b-media-body>
                            </b-media>
                        </b-col>
                        <b-col md="3" sm="6" class="mb-2 mb-md-0 mb-xl-0">
                            <b-media no-body>
                                <b-media-aside class="mr-2">
                                    <b-avatar size="48" variant="light-primary">
                                        <feather-icon size="24" icon="TrendingUpIcon" />
                                    </b-avatar>
                                </b-media-aside>
                                <b-media-body class="my-auto">
                                    <h4 class="font-weight-bolder mb-0">
                                        Portal
                                    </h4>
                                    <b-card-text class="font-small-3 mb-0">
                                        {{ item.customersPortalNumber }}
                                    </b-card-text>
                                </b-media-body>
                            </b-media>
                        </b-col>
                        <b-col md="3" sm="6" class="mb-2 mb-md-0 mb-xl-0">
                            <b-media no-body>
                                <b-media-aside class="mr-2">
                                    <b-avatar size="48" variant="light-success">
                                        <feather-icon size="24" icon="CheckIcon" />
                                    </b-avatar>
                                </b-media-aside>
                                <b-media-body class="my-auto">
                                    <h4 class="font-weight-bolder mb-0">
                                        Active
                                    </h4>
                                    <b-card-text class="font-small-3 mb-0">
                                        {{ item.customersPortalActiveNumber }}
                                    </b-card-text>
                                </b-media-body>
                            </b-media>
                        </b-col>
                        <b-col md="3" sm="6" class="mb-2 mb-md-0">
                            <b-media no-body>
                                <b-media-aside class="mr-2">
                                    <b-avatar size="48" variant="light-danger">
                                        <feather-icon size="24" icon="AlertTriangleIcon" />
                                    </b-avatar>
                                </b-media-aside>
                                <b-media-body class="my-auto">
                                    <h4 class="font-weight-bolder mb-0">
                                        Pending
                                    </h4>
                                    <b-card-text class="font-small-3 mb-0">
                                        {{ item.customersPortalPendingNumber }}
                                    </b-card-text>
                                </b-media-body>
                            </b-media>
                        </b-col>
                    </b-row>
                    <hr>
                    <b-row>
                        <b-col md="3" sm="6" class="mb-2 mb-md-0 mb-xl-0">
                            <b-media no-body>
                                <b-media-aside class="mr-2">
                                    <b-avatar size="48" variant="light-warning">
                                        <feather-icon size="24" icon="UserUpIcon" />
                                    </b-avatar>
                                </b-media-aside>
                                <b-media-body class="my-auto">
                                    <h4 class="font-weight-bolder mb-0">
                                        Suppliers
                                    </h4>
                                    <b-card-text class="font-small-3 mb-0">
                                        {{ item.suppliersNumber }}
                                    </b-card-text>
                                </b-media-body>
                            </b-media>
                        </b-col>
                        <b-col md="3" sm="6" class="mb-2 mb-md-0 mb-xl-0">
                            <b-media no-body>
                                <b-media-aside class="mr-2">
                                    <b-avatar size="48" variant="light-primary">
                                        <feather-icon size="24" icon="TrendingUpIcon" />
                                    </b-avatar>
                                </b-media-aside>
                                <b-media-body class="my-auto">
                                    <h4 class="font-weight-bolder mb-0">
                                        Portal
                                    </h4>
                                    <b-card-text class="font-small-3 mb-0">
                                        {{ item.suppliersPortalNumber }}
                                    </b-card-text>
                                </b-media-body>
                            </b-media>
                        </b-col>
                        <b-col md="3" sm="6" class="mb-2 mb-md-0 mb-xl-0">
                            <b-media no-body>
                                <b-media-aside class="mr-2">
                                    <b-avatar size="48" variant="light-success">
                                        <feather-icon size="24" icon="CheckIcon" />
                                    </b-avatar>
                                </b-media-aside>
                                <b-media-body class="my-auto">
                                    <h4 class="font-weight-bolder mb-0">
                                        Active
                                    </h4>
                                    <b-card-text class="font-small-3 mb-0">
                                        {{ item.suppliersPortalActiveNumber }}
                                    </b-card-text>
                                </b-media-body>
                            </b-media>
                        </b-col>
                        <b-col md="3" sm="6" class="mb-2 mb-md-0">
                            <b-media no-body>
                                <b-media-aside class="mr-2">
                                    <b-avatar size="48" variant="light-danger">
                                        <feather-icon size="24" icon="AlertTriangleIcon" />
                                    </b-avatar>
                                </b-media-aside>
                                <b-media-body class="my-auto">
                                    <h4 class="font-weight-bolder mb-0">
                                        Pending
                                    </h4>
                                    <b-card-text class="font-small-3 mb-0">
                                        {{ item.suppliersPortalPendingNumber }}
                                    </b-card-text>
                                </b-media-body>
                            </b-media>
                        </b-col>
                    </b-row>
                </b-card-body>
            </b-card>
        </b-overlay>
    </div>
</template>

<script>
export default {
    data() {
        return {
            showLoading: true,
            companies: [
            ],
        }
    },
    async created() {
        this.loadData();
    },
    methods: {
        async loadData() {
            this.showLoading = true;
            // let response = await this.$http.get("supervisor").catch(() => this.showLoading = false);
            // this.companies = response.data;
            // await this.$http.get("firebase").catch(() => this.showLoading = false);
            this.showLoading = false;
        }
    }
}
</script>